import React from 'react'
import './pagination.css';

const Pagination = ({numPages, currentPage, setCurrentPage}) => {
    const pageNumbers = [...Array(numPages + 1).keys()].slice(1)
    const goToNextPage = () => {
        if(currentPage !== numPages) 
            setCurrentPage(currentPage + 1);
        else 
            setCurrentPage(currentPage);
    }
    const goToPrevPage = () => {
        if(currentPage !== 1) 
            setCurrentPage(currentPage - 1);
        else    
            setCurrentPage(1);
    }
    return (
        <nav>
            <ul className='pagination justify-content-center'>
                <li className="page-item-first">
                    <a className="page-link" 
                        onClick={goToPrevPage} 
                        href='#'>
                        {'<'}
                    </a>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                        <a onClick={() => setCurrentPage(pgNumber)}  
                            className='page-link' 
                            href='#'>
                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className="page-item-last">
                    <a className="page-link" 
                        onClick={goToNextPage}
                        href='#'>
                        {'>'}
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination