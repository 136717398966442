import axios from "axios";

const apiUrl = "https://gitlab.com/api/v4";
const projectToken = "glpat-g6pLQHJbT7e1NY37nFiX";
const projectId = "54661575";

const config = {
    headers: {"PRIVATE-TOKEN": projectToken},
    params: { per_page: 100},
}


export const getCommits = async () => {
    try {
        let commits = [];
        let currPage = 1;
        let more = true;

        while (more) {
            const response = await axios.get(`${apiUrl}/projects/${projectId}/repository/commits`, 
            { ...config, params: { ...config.params, page: currPage } });
            if (response.data.length > 0) {
                commits = commits.concat(response.data);
                currPage += 1;
            } else {
                more = false;
            }
        }
        console.log(commits);
        return commits;
    } catch (error) {
        return [];
    }
};

export const getIssues = async () => {
    try {
        let issues = []
        let currPage = 1;
        let more = true;
        while (more) {
            const response = await axios.get(`${apiUrl}/projects/${projectId}/issues`, 
            { ...config, params: { ...config.params, page: currPage } });
            if (response.data.length > 0) {
                issues = issues.concat(response.data);
                currPage += 1;
            } else {
                more = false;
            }
        }
        console.log(issues);
        return issues;
    } catch (error) {
        return [];
    }
}