              export const people_information = [
    {
        imageSrc: "assets/people/shyamli.png",
        name: "Shyamli N Channabasappa",
        gitlab: "shyamc23",
        email: "shyamli.channa@utexas.edu",
        role: "Front-end",
        bio: "I am third year Computer Science major at UT Austin. Outside of class, I enjoy working out and reading.",
        tests: "0"
    },
    {
        imageSrc: "assets/people/sam.png",
        name: "Sam Gupta",
        gitlab: "sgg877",
        email: "sam78748@gmail.com",
        role: "Back-end",
        bio: "I am a fourth year student at UT Austin studying Computer Science. When not attending classes, I like to watch TV shows and go to the gym.",
        tests: "0"
    },
    {
        imageSrc: "assets/people/jerry.png",
        name: "Jerry Lin",
        gitlab: "jerry_lin",
        email: "jerry_lin@utexas.edu",
        role: "Front-end",
        bio: "I am a second year Computer Science major at UT Austin. I have interests in art, cooking, and video games.",
        tests: "43"
    },
    {
        imageSrc: 'assets/people/shehreen.png',
        name: "Shehreen Rahman",
        gitlab: "shehreenr",
        email: "shehreen.rahmann@gmail.com",
        role: "Front-end",
        bio: "I am a third year CS major at UT. During my free time, I enjoy reading and cooking.",
        tests: "0"
    },
    {
        imageSrc: 'assets/people/ritvik.png',
        name: "Ritvik Renikunta",
        gitlab: "ritvik.renikunta",
        email: "ritvik.renikunta@utexas.edu",
        role: "Back-end",
        bio: "I am a third year CS student at UT Austin. Outside of academics, I enjoy working out and playing tennis.",
        tests: "9"
    }
];

export const tools_information = [
    {
        imageSrc: "assets/tools/react.png",
        name: "React"
    },
    {
        imageSrc: "assets/tools/bootstrap.png",
        name: "Bootstrap"
    },
    {
        imageSrc: "assets/tools/aws.png",
        name: "AWS"
    },
    {
        imageSrc: "assets/tools/gitlab.png",
        name: "Gitlab"
    },
    {
        imageSrc: "assets/tools/postman.png",
        name: "Postman"
    },
    {
        imageSrc: "assets/tools/jest.png",
        name: "Jest"
    },
    {
        imageSrc: "assets/tools/selenium.png",
        name: "Selenium"
    },
    {
        imageSrc: "assets/tools/docker.png",
        name: "Docker"
    },
    {
        imageSrc: "assets/tools/python.png",
        name: "Python"
    }
];


