import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import './county_card.css';

async function getImage(countyName) {
  console.log(countyName)
  try {
    const response = await fetch('https://en.wikipedia.org/w/api.php?action=query&titles=' +String(countyName) +'_County,_Texas&prop=pageimages&format=json&pithumbsize=250&origin=*');
    const data = await response.json();
    const result = JSON.parse(JSON.stringify(data.query.pages));
    const filtered = Object.values(result)
    const finalResult = filtered.find(value => value.title.includes(countyName));
    console.log(finalResult.thumbnail.source);
    return finalResult.thumbnail.source;
  } catch (error) {
    return [];
  }
}

function CountyCard(props) {
    const [imageUrl, setImage] = useState("");
    const navigate = useNavigate();
    const queries = props.cardQuery ? props.cardQuery.split(' ') : []
    
    const [isVisible, setIsVisible] = useState(false);
    const [animationPlayed, setAnimationPlayed] = useState(false);
    const cardRef = React.useRef();

    useEffect(() => {
        const handleScroll = () => {
            const elementTop = cardRef.current.getBoundingClientRect().top;
            const isVisible = elementTop < window.innerHeight - 100;
            setIsVisible(isVisible);
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    useEffect(() => {
        if (isVisible && !animationPlayed) {
            setAnimationPlayed(true);
        }
    }, [isVisible, animationPlayed]);
    
    useEffect(() => {
      const fetchPost = async () => {
        var countyName = props.name.split(" County")[0];
        const response = await getImage(countyName);
        setImage(response);
      }
      fetchPost();
    }, [props.name]);
    
    return(
        <Card className={`county-card ${isVisible || animationPlayed ? 'visible' : ''}`} ref={cardRef}>
            <Card.Img as={Image} variant="top" width={150} height={175} src={imageUrl}/>
            <Card.Body>
                <Card.Header className="county-text-head"><Highlighter searchWords={queries} textToHighlight={props.name}/></Card.Header>
                <Card.Text className="county-text-start"><b>Population:</b> <Highlighter searchWords={queries} textToHighlight={props.population.toString()}/></Card.Text>
                <Card.Text className="county-text-start"><b>Number lacking literacy:</b> <Highlighter searchWords={queries} textToHighlight={props.num_illiterate.toString()}/></Card.Text>
                <Card.Text className="county-text-start"><b>Percent lacking literacy:</b> <Highlighter searchWords={queries} textToHighlight={props.percent_illiterate.toString()}/>%</Card.Text>
                <Card.Text className="county-text-start"><b>Upper bound:</b> <Highlighter searchWords={queries} textToHighlight={props.upper_bound.toString()}/>%</Card.Text>
                <Card.Text className="county-text-start"><b>Lower bound:</b> <Highlighter searchWords={queries} textToHighlight={props.lower_bound.toString()}/>%</Card.Text>
                <Button className="county-learn-more" onClick={() => navigate(`/counties/${props.id}`, {state: {props: props}})}><b>Learn More</b></Button>
            </Card.Body>
        </Card>
    )
}

export default CountyCard;