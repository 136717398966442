import React, { useState , useEffect} from 'react';
import { useParams} from 'react-router-dom';
import ProgramCard from './program_card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container';
import Pagination from '../pagination/pagination';
import { useLocation, useNavigate } from 'react-router-dom';

function LiteracyProgramsSearch() {
    const [programsResult, setProgramsResult] = useState([]);
    const [sort_category, setCategory] = useState("");
    const [span_resource, setResources] = useState("");
    const [sort_order, setOrder] = useState("");
    const {query} = useParams();
    const location = useLocation();
    // const query = location.state.query;

    const handleCategoryChange = (e) => {
      setCategory(e.target.value);
      setOrder('');
    };

    useEffect(() => {
        const getPrograms = getLiteracyProgramsQueryFromEndpoint(query);
        const order = sort_order ? sort_order : '&asc';
        getPrograms.then(function(result){
            if(sort_category === "county") {
              if(order === "&asc") {
                result.sort((a, b)=> a.county > b.county ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.county < b.county ? 1: -1)
              }
            } else if(sort_category === "name") {
              if(order === "&asc") {
                result.sort((a, b)=> a.name > b.name ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.name < b.name ? 1: -1)
              }
            } else if(sort_category === "address") {
              if(order === "&asc") {
                result.sort((a, b)=> a.address > b.address ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.address < b.address ? 1: -1)
              }
            } else if(sort_category === "phone_number") {
              if(order === "&asc") {
                result.sort((a, b)=> a.phone_number > b.phone_number ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.phone_number < b.phone_number ? 1: -1)
              }
            }
            if(span_resource === "yes") {
              result = result.filter(a => a.speak_spanish)
            } else if(span_resource === "no") {
              result = result.filter(a => !a.speak_spanish)
            }
            setProgramsResult(result);
        });
    }, [query, sort_category, sort_order, span_resource]);
    

    const [currentPage, setCurrentPage] = useState(1); // current page the user is on 
    const [cardsPerPage] = useState(15);
    let indexOfLastCard = currentPage * cardsPerPage;
    let indexOfFirstCard = indexOfLastCard - cardsPerPage; 
    
    const numPrograms = programsResult.length;
    // total number of pages 
    const numPages = Math.ceil(programsResult.length / cardsPerPage);

    let currentPrograms = programsResult.slice(indexOfFirstCard, indexOfLastCard);
    if (currentPrograms.length < cardsPerPage) {
        indexOfLastCard = indexOfFirstCard + currentPrograms.length;
    }

    return (
        <div style={{margin: 'auto', overflow: 'hidden'}}>
            <div style={{backgroundColor: '#293241', color: 'white', fontSize:35, fontWeight:'bold', padding:'25px'}}>
                Literacy Programs
            </div>
            <div style={{fontSize: '25px', fontWeight: 'bold', color: '#293241'}}>
                {numPrograms} Results for "{query}"
            </div>
            <div className="mb-3" sm={4} style={{ display: 'flex', justifyContent: 'center', padding: '25px'}}>
            <Form.Group controlId="spanish_resources">
              <Form.Select value={span_resource} onChange={(e) => setResources(e.target.value)}>
                <option value="">All Programs</option>
                <option value="yes">Spanish Resources</option>
                <option value="no">No Spanish Resources</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="sort_category" style={{paddingLeft: '25px'}}>
              <Form.Select value={sort_category} onChange={handleCategoryChange}>
                <option value="">Sort By</option>
                <option value="county">County</option>
                <option value="name">Name</option>
                <option value="address">Address</option>
                <option value="phone_number">Phone Number</option>
              </Form.Select>
            </Form.Group>
            
            {sort_category && (
                <Form.Group controlId="sort_order" style={{display: 'flex', alignItems: 'center', paddingLeft: '25px'}}>
                    <Form.Select value={sort_order} onChange={(e) => setOrder(e.target.value)}>
                        <option value={`&asc`}>Ascending</option>
                        <option value={`&desc`}>Descending</option>
                    </Form.Select>
                </Form.Group>
            )}
          </div>
            <Pagination
                numPages={numPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <div style={{fontSize: '20px', fontWeight: 'bold', color: '#293241'}}>
                Displaying {indexOfFirstCard} - {indexOfLastCard} out of {numPrograms} Total Programs:
            </div>
            <Container fluid style={{justifyContent: 'center'}}>
                <Row xs={1} sm={2} md={3} lg={4} className="justify-content-md-center" style={{padding: '10px'}}>
                    {currentPrograms.map((program, id) => (
                        <Col key={id} style={{ display: 'flex', justifyContent: 'center', padding: '10px'}}>
                            {/* {()=>setImage(getImageInfo(program.name))}; */}
                            <ProgramCard cardQuery={query} imageSrc={"assets/programs/literacy-for-texas.png"} name={program.name} county={program.county} library={""} address={program.address}
                            spanish={program.speak_spanish} id={program.id} number={program.phone_number} mapSrc={""}>
                            </ProgramCard>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Pagination
                numPages={numPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
      </div>
    )
}


export function getLiteracyProgramsQueryFromEndpoint(query) {
    var addedStr = query.split(' ').join('_');
    return fetch(`https://cs373-backend.literatetx.me/search/programs/${addedStr}`)
        .then(response => response.json())
        .then((jsonPrograms) => {
            return jsonPrograms.data;
        })
        .catch((error) => {
            console.error(error);
        })
}


export default LiteracyProgramsSearch; 