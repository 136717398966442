import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function LineGraph(props) {
    var xData = props.xData;
    var yData = props.yData;
    var width = props.width;
    var height = props.height;
    var xAxisTitle = props.xAxis;
    var yAxisTitle = props.yAxis;
    
    const svgRef = useRef();

    useEffect(() => {
        const margin = { top: 20, right: 20, bottom: 50, left: 35 };
        width = width - margin.left - margin.right;
        height = height - margin.top - margin.bottom;

        const svg = d3.select(svgRef.current);

        svg.selectAll('*').remove();

        const xScale = d3.scaleLinear()
            .domain([d3.min(xData), d3.max(xData)])
            .range([0, width]);

        const yScale = d3.scaleLinear()
            .domain([d3.min(yData), d3.max(yData)])
            .range([height, 0]);

        const xAxis = d3.axisBottom(xScale);
        const yAxis = d3.axisLeft(yScale);

        svg.append("g")
            .attr("transform", `translate(${margin.left + 10}, ${height + 5})`)
            .call(xAxis);

        svg.append("text")
            .attr("x", width / 2)
            .attr("y", height + margin.top + 15)
            .style("text-anchor", "middle")
            .text(xAxisTitle);

        svg.append("g")
            .attr("transform", `translate(${margin.left + 10}, 5)`)
            .call(yAxis);

        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("x", -height / 2)
            .attr("y", -margin.left + 15)
            .style("text-anchor", "middle")
            .text(yAxisTitle);

        svg.selectAll(".point")
            .data(xData.map((d, i) => ({ x: d, y: yData[i]})))
            .enter().append("circle")
            .attr("class", "point")
            .attr("cx", d => xScale(d.x))
            .attr("cy", d => yScale(d.y))
            .attr("r", 3)
            .attr("transform", `translate(${margin.left + 10}, 5)`)
            .style("fill", "#5752D1");
    }, [xData, yData, width, height, xAxisTitle, yAxisTitle]);

    return (
        <div>
            <svg width={width} height={height} ref={svgRef}></svg>
        </div> 
    );
}

export default LineGraph;