import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function BarGraph(props) {
    var xData = props.xData;
    var yData = props.yData;
    var width = props.width;
    var height = props.height;
    var yAxisTitle = props.yAxis;

    const svgRef = useRef();

    useEffect(() => {
      const svg = d3.select(svgRef.current);

      const margin = { top: 20, right: 20, bottom: 50, left: 50 };
      const newWidth = width - margin.left - margin.right;
      const newHeight = height - margin.top - margin.bottom;
  
      const xScale = d3.scaleBand()
        .domain(xData)
        .range([0, newWidth])
        .padding(0.1);
  
      const yScale = d3.scaleLinear()
        .domain([40, d3.max(yData)])
        .range([newHeight, 0]);

      const xAxis = d3.axisBottom(xScale);
      
      const yAxis = d3.axisLeft(yScale);
  
      svg.selectAll('rect')
        .data(xData)
        .enter()
        .append('rect')
        .attr('x', (d) => xScale(d))
        .attr('y', (d, i) => yScale(yData[i]))
        .attr('width', xScale.bandwidth())
        .attr('height', (d, i) => newHeight - yScale(yData[i]))
        .attr('transform', `translate(50,0)`)
        .attr('fill', '#23adad');

      svg.append('text')
        .attr("x", -height / 2)
        .attr("y", 20)
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'top')
        .text(yAxisTitle);

      svg.append('g')
        .attr("class", "axis")
        .attr('transform', `translate(50,${newHeight})`)
        .call(xAxis)
        .selectAll('text')

      svg.append('g')
        .attr("class", "axis")
        .attr("transform", "translate(50,0)")
        .call(yAxis);
    
    }, [xData, yData, width, height, yAxisTitle]);
    
    return (
      <svg style = {{width: width, height: height}} ref={svgRef}></svg>
    );
}

export default BarGraph;