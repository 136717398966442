import { Route,Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar/navbar';
import Home from './components/home/home';
import Critiques from './components/critiques/critiques'
import Search from './components/navbar/search';
import AboutMe from './components/about/about_me';
import Counties from './components/counties/counties';
import CountiesSearch from './components/counties/counties_search';
import LiteracyPrograms from './components/literacy_programs/literacy_programs';
import LiteracyProgramsSearch from './components/literacy_programs/literacy_programs_search';
import Libraries from './components/libraries/libraries';
import LibrariesSearch from './components/libraries/libraries_search';
import CountyInstance from './components/counties/county_instance';
import LiteracyProgramInstance from './components/literacy_programs/literacy_program_instance';
import LibraryInstance from './components/libraries/library_instance';
import Visualizations from './components/visualizations/visualizations';


function App() {
  return (
    <div className="App">
      <Navbar/>
      <div>
        <Routes>        
          <Route path="/" element={<Home/>} />
          <Route path="/about_me" element={<AboutMe />}/>
          <Route path="/counties" element={<Counties />}/>
          <Route path="/literacy_programs" element={<LiteracyPrograms />}/>
          <Route path="/libraries" element={<Libraries/>}/>
          <Route path="/visualizations" element={<Visualizations/>}/>
          <Route path="/critiques" element={<Critiques/>}/>
          <Route path="/counties/:id" element={<CountyInstance/>}/>
          <Route path="/literacy_programs/:id" element={<LiteracyProgramInstance/>}/>
          <Route path="/libraries/:id" element={<LibraryInstance/>}/>
          <Route path="/counties_search/:query" element={<CountiesSearch />}/>
          <Route path="/libraries_search/:query" element={<LibrariesSearch />}/>
          <Route path="/programs_search/:query" element={<LiteracyProgramsSearch />}/>
          <Route path="/search/:query" element={<Search/>} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
