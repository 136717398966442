import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import './about.css'

function AboutCard(props) {
    const [isVisible, setIsVisible] = useState(false);
    const [animationPlayed, setAnimationPlayed] = useState(false);
    const cardRef = React.useRef();

    useEffect(() => {
        const handleScroll = () => {
            const elementTop = cardRef.current.getBoundingClientRect().top;
            const isVisible = elementTop < window.innerHeight - 100;
            setIsVisible(isVisible);
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    useEffect(() => {
        if (isVisible && !animationPlayed) {
            setAnimationPlayed(true);
        }
    }, [isVisible, animationPlayed]);

    return (
        <Card className={`about-card ${isVisible || animationPlayed ? 'visible' : ''}`} ref={cardRef}>
            <Card.Img as={Image} variant="top" width={225} height={400} src={props.imageSrc}/>
            <Card.Body>
                <Card.Header className="about-text-head">{props.name}</Card.Header>
                <Card.Text className="about-text-start"><b>GitLab ID: </b>{props.gitlab}</Card.Text> 
                <Card.Text className="about-text-start"><b>Email: </b>{props.email}</Card.Text>
                <Card.Text className="about-text-start"><b>Role: </b>{props.role}</Card.Text>
                <Card.Text className="about-text-start"><b>Bio: </b>{props.bio}</Card.Text>
                <Card.Text className="about-text-start"><b>Commits:</b> {props.commits}</Card.Text>
                <Card.Text className="about-text-start"><b>Issues: </b>{props.issues}</Card.Text>
                <Card.Text className="about-text-start"><b>Tests: </b>{props.tests}</Card.Text>
            </Card.Body>
        </Card>
    )
}

export default AboutCard;
