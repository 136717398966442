import React, { useState, useEffect } from 'react';
import ProgramCard from './program_card';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { program_information } from './program_info';
import Pagination from '../pagination/pagination';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";

function LiteracyPrograms() {
    const [programsResult, setProgramResult] = useState([])
    const getPrograms = getProgramsFromEndpoint();
    const [programImage, setImage] = useState([]);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const handleChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            navigateToSearch();
        }
    };

    const navigateToSearch = () => {
        if (searchText.trim() !== '') {
            window.location.href = `/programs_search/${searchText}`;
            setSearchText('');
        }
    };

    const [sort_category, setCategory] = useState("");
    const [span_resource, setResources] = useState("");
    const [sort_order, setOrder] = useState("");
    
    // getPrograms.then(function(result){
    //   setProgramResult(result)
    // })
    const handleCategoryChange = (e) => {
      setCategory(e.target.value);
      setOrder('');
    };

    useEffect(() => {
      console.log(sort_category);
      const order = sort_order ? sort_order : '&asc';
      const apiParam = sort_category ? '?sort=' + sort_category + order: '';
      console.log(apiParam);
      getProgramsFromEndpoint(apiParam, span_resource).then(function(result){
        setProgramResult(result)
      }) 
    }, [sort_category, sort_order, span_resource]);

    const [currentPage, setCurrentPage] = useState(1); // current page the user is on 
    const [cardsPerPage] = useState(15);
    let indexOfLastCard = currentPage * cardsPerPage;
    let indexOfFirstCard = indexOfLastCard - cardsPerPage; 
    
    // total number of pages 
    const numPages = Math.ceil(programsResult.length / cardsPerPage);
    // cards to be displayed on the current page
    const currentPrograms = programsResult.slice(indexOfFirstCard, indexOfLastCard);
    if (currentPrograms.length < cardsPerPage) {
      indexOfLastCard = indexOfFirstCard + currentPrograms.length;
    }

    return (
      <><div style={{ margin: 'auto', overflow: 'hidden' }}>
        <div style={{ backgroundColor: '#293241', color: 'white', fontSize: 35, fontWeight: 'bold', padding: '25px' }}>
          Literacy Programs
        </div>
        
        <div className="search-and-sort-container" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
          <div className='input-box-model'>
            <FaSearch id="search-programs-icon" onClick={navigateToSearch}/>
            <input type="search" 
              name="search-programs" 
              id="search-programs"
              data-testid="search-programs"
              className="search-input" 
              placeholder="Search programs..." 
              onKeyUp={handleKeyPress}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3" sm={4} style={{ display: 'flex', justifyContent: 'center', padding: '25px'}}>
            <Form.Group controlId="spanish_resources">
              <Form.Select value={span_resource} onChange={(e) => setResources(e.target.value)}>
                <option value="">All Programs</option>
                <option value="yes">Spanish Resources</option>
                <option value="no">No Spanish Resources</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="sort_category" style={{paddingLeft: '25px'}}>
              <Form.Select value={sort_category} onChange={handleCategoryChange}>
                <option value="">Sort By</option>
                <option value="county">County</option>
                <option value="name">Name</option>
                <option value="address">Address</option>
                <option value="phone_number">Phone Number</option>
              </Form.Select>
            </Form.Group>
            
            {sort_category && (
                <Form.Group controlId="sort_order" style={{display: 'flex', alignItems: 'center', paddingLeft: '25px'}}>
                    <Form.Select value={sort_order} onChange={(e) => setOrder(e.target.value)}>
                        <option value={`&asc`}>Ascending</option>
                        <option value={`&desc`}>Descending</option>
                    </Form.Select>
                </Form.Group>
            )}
          </div>
        </div>
        <div style={{ fontSize: '25px', fontWeight: 'bold', color: '#293241' }}>
          Number of literacy programs: {programsResult.length}
        </div>
        <Pagination
                numPages={numPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
        />
        <div style={{fontSize: '20px', fontWeight: 'bold', color: '#293241'}}>
            Displaying {indexOfFirstCard} - {indexOfLastCard} out of {programsResult.length} Total Programs:
        </div>
        <Container fluid style={{ display: 'flex', justifyContent: 'center'}}>
          <Row xs={1} sm={2} md={3} lg={4} style={{ padding: '10px', justifyContent: 'center' }}>
            {currentPrograms.map((program, id) => (
              <Col key={id} style={{ display: 'flex', justifyContent: 'center', padding: '10px'}}>
                {/* {()=>setImage(getImageInfo(program.name))}; */}
                <ProgramCard cardQuery={""} imageSrc={"assets/programs/literacy-for-texas.png"} name={program.name} county={program.county} library={""} address={program.address}
                  spanish={program.speak_spanish} id={program.id} number={program.phone_number} mapSrc={""}></ProgramCard>
              </Col>
            ))}
          </Row>
        </Container>
        <Pagination
          numPages={numPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div></>
    )
}

export default LiteracyPrograms;

function getProgramsFromEndpoint(filters, spanish) {
  console.log(spanish)
  return fetch(`https://cs373-backend.literatetx.me/get_programs${filters}`)
      .then(response => response.json())
      .then((jsonPrograms) => {
          if(spanish === "yes") {
            return jsonPrograms.result.filter(program => program.county && program.speak_spanish)
          } else if(spanish === "no"){
            return jsonPrograms.result.filter(program => program.county && !program.speak_spanish)
          } else {
            return jsonPrograms.result.filter(program => program.county)
          }
      })
      .catch((error) => {
          console.error(error);
      })
}