import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import LibraryCard from '../libraries/library_card';
import CountyCard from '../counties/county_card';
import { getNumIlliterate } from '../counties/counties';

async function getImage(countyName) {
  console.log(countyName)
  try {
    const response = await fetch('https://en.wikipedia.org/w/api.php?action=query&titles=' +String(countyName) +'_County,_Texas&prop=pageimages&format=json&pithumbsize=250&origin=*');
    const data = await response.json();
    const result = JSON.parse(JSON.stringify(data.query.pages));
    const filtered = Object.values(result)
    const finalResult = filtered.find(value => value.title.includes(countyName));
    console.log(finalResult.thumbnail.source);
    return finalResult.thumbnail.source;
  } catch (error) {
    return [];
  }
}

async function getCountyProps(countyName) {
  try {
    const jsonInfo = await fetch('https://cs373-backend.literatetx.me/get_counties');
    const data = await jsonInfo.json();
    const countyMatch = data.result.filter(county => county.name.includes(countyName));
    return countyMatch;
  } catch(error) {
    return [];
  }
}

async function getLibraryProps(countyName) {
  try {
    const response = await fetch('https://cs373-backend.literatetx.me/get_libraries');
    const data = await response.json();
    const filteredLibraries = data.result.filter(library => library.county.includes(countyName));
    return filteredLibraries;
  } catch (error) {
      return [];
  }
}

async function makeURL(address) {
  const formatted = address.replace(/\s/g,'+')
  var url = "https://www.google.com/maps/embed/v1/place?key=AIzaSyDiSH6IyCrFKQLNvAOsTDVbPDyLn7qZZL8&q=" + String(formatted) + ""
  return url;
}

function LiteracyProgramInstance() {


    const location = useLocation();
    const props = location.state.props;
    const [counties, setCounties] = useState([]);
    const[libraries, setLibraries] = useState([]);
    const[mapUrl, setMapURL] = useState([]);
    const [imageUrl, setImage] = useState("");
    const embedSrc = props.mapSrc;

    const navigate = useNavigate();

    useEffect(() => {
      async function fetchData() {
          const fetchedCounties = await getCountyProps(props.county);
          setCounties(fetchedCounties);

          const fetchedLibraries = await getLibraryProps(props.county);
          setLibraries(fetchedLibraries);
          
          const url = await makeURL(props.address);
          setMapURL(url);
      }
      fetchData();
  }, []);

    useEffect(() => {
      const fetchPost = async () => {
        var countyName = props.county;
        const response = await getImage(countyName);
        setImage(response);
      }
      fetchPost();
    }, []);
  

    return (
        <div>
            <Image src={imageUrl} width={375} height={400} style={{padding: '30px'}}/>
            <br/>
            <p class="text-middle"><b>Literacy Program Name:</b> {props.name}</p>
            <p class="text-middle"><b>County Name:</b> {props.county}</p>
            <p class="text-middle"><b>Address:</b> {props.address}</p>
            <p class="text-middle"><b>Phone Number:</b> {props.number}</p>
            <p class="text-middle"><b>Spanish or Not:</b> {props.spanish ? "Spanish Resources Available" : "No Spanish Resources"}</p>
            {/* <Button onClick={() => navigate(`/counties/${county.id}`, {state: {props: county}})}> Connection: {props.county} </Button>
            <br/> */}
            {<iframe
              title="myBook"
              src={mapUrl}
              height="600px"
              width="60%"
            ></iframe> }
            <p class="text-middle" style={{fontSize: '30px'}}><b>Connections:</b></p>
            <Row xs={1} sm={2} md={2} lg={4} className="g-0" style={{marginBottom: '1em', marginTop: '1em', padding: '20px'}}>
                {counties.map((county) => (
                    <Col key={county.id} style={{display: 'flex', justifyContent: 'center', padding: '10px'}}>
                        <CountyCard
                            id={county.id}
                            name={county.name}
                            population={county.population}
                            num_illiterate={getNumIlliterate(county.percent_literacy_lacking, county.population)}
                            imageSrc={county.imageSrc}
                            graphSrc={county.graphSrc}
                            embed={county.embed}
                            percent_illiterate={county.percent_literacy_lacking}
                            upper_bound={county.literacy_upper_bound}
                            lower_bound={county.literacy_lower_bound}
                        />
                    </Col>
                ))}
                </Row>
                <Row xs={1} sm={2} md={2} lg={4} className="g-0" style={{marginBottom: '1em', marginTop: '1em', padding: '20px'}}>
                {libraries.map((library) => (
                    <Col key={library.id} style={{display: 'flex', justifyContent: 'center', padding: '10px'}}>
                        <LibraryCard 
                            id={library.id} 
                            imageSrc={"/assets/programs/literacy-for-texas.png"} 
                            mapSrc={library.mapSrc} 
                            name={library.name} 
                            county={library.county}
                            city={library.city}
                            square_footage={library.square_footage}
                            audio_items={library.num_audio_items} 
                            video_items = {library.num_video_items}>
                        </LibraryCard>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default LiteracyProgramInstance;