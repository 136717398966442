import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function PieChart(props) {
    var data = props.data;
    const svgRef = useRef();
    const legendRef = useRef();

    useEffect(() => {
      const svg = d3.select(svgRef.current);
      const width = +svg.attr('width');
      const height = +svg.attr('height');
      const radius = Math.min(width, height) / 2;

      const color = d3.scaleOrdinal(d3.schemeCategory10);

      const pie = d3.pie()
                  .value(d => d.value)
                  .sort(null);

      const arc = d3.arc()
                  .innerRadius(0)
                  .outerRadius(radius);

      const arcs = pie(data);

      const g = svg.append('g')
                  .attr('transform', `translate(${width / 2},${height / 2})`);

      g.selectAll('path')
      .data(arcs)
      .enter()
      .append('path')
      .attr('fill', d => color(d.data.category))
      .attr('d', arc)
      .append('title')
      .text(d => `${d.data.category}: ${d.data.value}%`);

      g.selectAll('text')
      .data(arcs)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${arc.centroid(d)})`)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .text(d => `${d.data.value}%`);

      const legend = d3.select(legendRef.current);

      const legendItems = legend.selectAll('.legend-item')
                          .data(data)
                          .enter()
                          .append('g')
                          .attr('class', 'legend-item')
                          .attr('transform', (d, i) => `translate(0, ${i * 20})`);

      legendItems.append('rect')
                  .attr('width', 18)
                  .attr('height', 18)
                  .attr('fill', d => color(d.category));

      legendItems.append('text')
                  .attr('x', 24)
                  .attr('y', 9)
                  .attr('dy', '.35em')
                  .text(d => d.category);
    }, [data]);
  
    return (
      <div>
        <svg ref={svgRef} width={props.width} height={props.height}></svg>
        <svg ref={legendRef} width={props.width / 2} height={props.height}></svg>
      </div>
      
    );
}

export default PieChart;