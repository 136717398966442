import React, { useState } from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import { FaSearch } from "react-icons/fa";
import './navbar.css';

const Navbar=()=>{
    const location = useLocation();
    const navigate = useNavigate()
    const [searchText, setSearchText] = useState('');

    const handleChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            navigateToSearch();
        }
    };

    const navigateToSearch = () => {
        if (searchText.trim() !== '') {
            window.location.href = `/search/${searchText}`;
            setSearchText('');
        }
    };

    return (
        <nav className='navbar navbar-dark bg-dark  navbar-expand-sm' style={{flexWrap: 'wrap'}}>
            <div className='navbar-brand'>
                <img src="assets/texas.png" alt='' width={25} height={25} style={{marginRight: '5px', marginLeft: '5px'}} ></img>
                <Link className='header-icon' to="/"> LiterateTX </Link>
            </div>
            <div className='navbar-brand ml-auto'>
                <Link className={`header ${location.pathname === '/' ? 'active' : ''}`} to="/">Home</Link>
            </div>
            <div className='navbar-brand'>
                <Link className={`header ${location.pathname === '/about_me' ? 'active' : ''}`} to="/about_me">About</Link>
            </div>
            <div className='navbar-brand'>
                <Link className={`header ${location.pathname === '/counties' ? 'active' : ''}`} to="/counties">Counties</Link>
            </div>
            <div className='navbar-brand'>
                <Link className={`header ${location.pathname === '/literacy_programs' ? 'active' : ''}`} to="/literacy_programs">Literacy Programs</Link>
            </div>
            <div className='navbar-brand'>
                <Link className={`header ${location.pathname === '/libraries' ? 'active' : ''}`} to="/libraries">Libraries</Link>
            </div>
            <div className='navbar-brand'>
                <Link className={`header ${location.pathname === '/visualizations' ? 'active' : ''}`} to="/visualizations">Visualizations</Link>
            </div>
            <div className='navbar-brand'>
                <Link className={`header ${location.pathname === '/critiques' ? 'active' : ''}`} to="/critiques">Critiques</Link>
            </div>
            <div className='input-box-nav'>
                <FaSearch id="search-all-icon" onClick={navigateToSearch}/>
                <input type="search" 
                    name="search-all" 
                    id="search-all"
                    data-testid="search-all"
                    className="search-input" 
                    placeholder="Search..." 
                    onKeyUp={handleKeyPress}
                    onChange={handleChange}
                />
            </div>
            <div className="box"></div>
        </nav>
    )

}

function searchAll(query) {
    // TODO: link to search page including results from all instances
    console.log(query)
    
}

export default Navbar;