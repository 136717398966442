import React from 'react';
import { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import './library_card.css'

async function getImage(countyName) {
  console.log(countyName)
  try {
    const response = await fetch('https://en.wikipedia.org/w/api.php?action=query&titles=' +String(countyName) +'_County,_Texas&prop=pageimages&format=json&pithumbsize=250&origin=*');
    const data = await response.json();
    const result = JSON.parse(JSON.stringify(data.query.pages));
    const filtered = Object.values(result)
    const finalResult = filtered.find(value => value.title.includes(countyName));
    console.log(finalResult.thumbnail.source);
    return finalResult.thumbnail.source;
  } catch (error) {
    return [];
  }
}

function LibraryCard(props) {
    const [imageUrl, setImage] = useState("");
    const navigate = useNavigate();
    const queries = props.cardQuery ? props.cardQuery.split(' ') : []
    
    const [isVisible, setIsVisible] = useState(false);
    const [animationPlayed, setAnimationPlayed] = useState(false);
    const cardRef = React.useRef();

    useEffect(() => {
        const handleScroll = () => {
            const elementTop = cardRef.current.getBoundingClientRect().top;
            const isVisible = elementTop < window.innerHeight - 100;
            setIsVisible(isVisible);
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    useEffect(() => {
        if (isVisible && !animationPlayed) {
            setAnimationPlayed(true);
        }
    }, [isVisible, animationPlayed]);
    
    useEffect(() => {
      const fetchPost = async () => {
        var countyName = props.county;
        const response = await getImage(countyName);
        setImage(response);
      }
      fetchPost();
    }, [props.county]);

    return (
        <Card className={`library-card ${isVisible || animationPlayed ? 'visible' : ''}`} ref={cardRef}>
            <Card.Img as={Image} variant="top" width={150} height={175} src={imageUrl}/>
            <Card.Body>
                <Card.Header className="library-text-head"><Highlighter searchWords={queries} textToHighlight={props.name}/></Card.Header>
                <Card.Text className="library-text-start"><b>City:</b> <Highlighter searchWords={queries} textToHighlight={props.city}/></Card.Text>
                <Card.Text className="library-text-start"><b>County:</b> <Highlighter searchWords={queries} textToHighlight={props.county}/> </Card.Text>
                <Card.Text className="library-text-start"><b>Square Footage:</b> <Highlighter searchWords={queries} textToHighlight={props.square_footage.toString()}/> ft²</Card.Text>
                <Card.Text className="library-text-start"><b>Audio Items:</b> <Highlighter searchWords={queries} textToHighlight={props.audio_items.toString()}/></Card.Text>
                <Card.Text className="library-text-start"><b>Video Items:</b> <Highlighter searchWords={queries} textToHighlight={props.video_items.toString()}/></Card.Text>
                <Button className="library-learn-more" onClick={() => navigate(`/libraries/${props.id}`, {state: {props: props}})}><b>Learn More</b></Button>
            </Card.Body>
        </Card>
    )
}

export default LibraryCard;