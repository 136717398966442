import React, { useEffect, useState } from 'react';
import LineGraph from "./line_graph";
import BarGraph from './bar_graph';
import PieChart from './pie_chart';

function Visualizations() {
    const [square_footage, set_square_footage] = useState([]);
    const [num_video_items, set_num_video_items] = useState([]);
    const [county_names, set_county_names] = useState([]);
    const [percent_literacy_lacking, set_percent_literacy_lacking] = useState([]);
    const [libraries, set_libraries] = useState([]);
    const [counties, set_counties] = useState([]);
    const [num_spanish, set_num_spanish] = useState(0);
    const [num_non_spanish, set_num_non_spanish] = useState(0);
    const [programs_data, set_programs_data] = useState([]);

    useEffect(() => {
        const fetchAndSetData = async () => {
            let result = await get_libraries();
            set_libraries(result);
        };

        fetchAndSetData();
    }, []);

    useEffect(() => {
        const fetchAndSetData = async () => {
            let result = await get_counties();
            console.log(result);
            set_counties(result);
        };

        fetchAndSetData();
    }, []);

    useEffect(() => {
        const fetchAndSetData = async () => {
            let result = await get_spanish_programs();
            console.log(result);
            set_num_spanish(result.length);
        };

        fetchAndSetData();
    }, []);

    useEffect(() => {
        const fetchAndSetData = async () => {
            let result = await get_non_spanish_programs();
            console.log(result);
            set_num_non_spanish(result.length);
        };

        fetchAndSetData();
    }, []);
    

    useEffect(() => {
        let temp_square_footage = [];
        let temp_num_video_items = [];
        for (let i = 0; i < libraries.length; i++) {
            temp_square_footage.push(libraries[i].square_footage);
            temp_num_video_items.push(libraries[i].num_video_items);
        }

        [temp_square_footage, temp_num_video_items] = remove_outliers(temp_square_footage, temp_num_video_items);

        set_square_footage(temp_square_footage);
        set_num_video_items(temp_num_video_items);

    }, [libraries])

    useEffect(() => {
        console.log(counties);
        let temp_county_names = [];
        let temp_percent_literacy_lacking = [];
        for (let i = 0; i < counties.length; i++) {
            temp_county_names.push(counties[i].name);
            temp_percent_literacy_lacking.push(counties[i].percent_literacy_lacking);
            if (i === 4) { // Need this here instead of i < 5 in for loop construction
                break; // Otherwise, this function will run before counties is populated
            }
        }
 
        set_county_names(temp_county_names);
        set_percent_literacy_lacking(temp_percent_literacy_lacking);

    }, [counties])

    useEffect(() => {
        var total_programs = num_spanish + num_non_spanish;
        var data = [
            { category: 'Spanish', value: ((num_spanish / total_programs) * 100).toFixed(2) },
            { category: 'Non-Spanish', value: ((num_non_spanish / total_programs) * 100).toFixed(2) }
        ];
        set_programs_data(data);

    }, [num_spanish, num_non_spanish])

    return (
        <div>
            <div>
                <h1>Square footage vs number of video items for Texas Libraries</h1>
                <LineGraph xData={square_footage} yData={num_video_items} width={650} height={400} xAxis={"Square Footage"} yAxis={"Number of Video Items"}/>
            </div>
            <div>
                <h1>Counties with five worst literacy rates</h1>
                <BarGraph xData={county_names} yData={percent_literacy_lacking} width={650} height={400} yAxis={"Literacy Rate Lacking (%)"}/>
            </div>
            <div>
                <h1>Literacy Programs proportion between Spanish and no Spanish</h1>
                <PieChart data={programs_data} width={400} height={400}/>
            </div>
        </div>
        
    )
}

async function get_libraries() {
    return fetch("https://cs373-backend.literatetx.me/get_libraries")
        .then(response => response.json())
        .then((jsonLibraries) => {
            return jsonLibraries.result;
        })
        .catch((error) => {
            console.error(error);
        });
}

async function get_counties() {
    return fetch("https://cs373-backend.literatetx.me/get_counties?sort=percent_literacy_lacking")
        .then(response => response.json())
        .then((jsonCounties) => {
            return jsonCounties.result;
        })
        .catch((error) => {
            console.error(error);
        });
}

async function get_spanish_programs() {
    return fetch("https://cs373-backend.literatetx.me/get_programs?speak_spanish=True")
        .then(response => response.json())
        .then((jsonPrograms) => {
            return jsonPrograms.result;
        })
        .catch((error) => {
            console.error(error);
        }); 
}

async function get_non_spanish_programs() {
    return fetch("https://cs373-backend.literatetx.me/get_programs?speak_spanish=False")
        .then(response => response.json())
        .then((jsonPrograms) => {
            return jsonPrograms.result;
        })
        .catch((error) => {
            console.error(error);
        }); 
}

function remove_outliers(pointsX, pointsY) {
    const points = pointsX.map((x, i) => ({ x, y: pointsY[i] }));

    points.sort((a, b) => a.x - b.x);
    points.splice(-10);

    points.sort((a, b) => a.y - b.y);

    const newPointsX = points.map(point => point.x);
    const newPointsY = points.map(point => point.y);

    return [newPointsX, newPointsY];
}

export default Visualizations;