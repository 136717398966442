import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import ProgramCard from '../literacy_programs/program_card';
import LibraryCard from '../libraries/library_card';

async function getLibraryProps(countyName) {
    try {
        const response = await fetch('https://cs373-backend.literatetx.me/get_libraries');
        const data = await response.json();
        const filteredLibraries = data.result.filter(library => library.county.includes(countyName));
        return filteredLibraries;
    } catch (error) {
        return [];
    }
}

async function getLiteracyProps(countyName) {
    try {
        const response = await fetch('https://cs373-backend.literatetx.me/get_programs');
        const data = await response.json();
        const filteredPrograms = data.result.filter(program => program.county === countyName);
        return filteredPrograms;
    } catch (error) {
        return [];
    }
}

async function getWiki(countyName) {
  console.log(countyName)
  try {
    const response = await fetch('https://en.wikipedia.org/w/api.php?action=query&titles=' +String(countyName) +'_County,_Texas&prop=extracts&format=json&exintro=1&origin=*');
    const data = await response.json();
    const result = JSON.parse(JSON.stringify(data.query.pages));
    const filtered = Object.values(result)
    const finalResult = filtered.filter(value => value.title.includes(countyName));
    return finalResult;
  } catch (error) {
    return [];
  }
}

async function getImage(countyName) {
  console.log(countyName)
  try {
    const response = await fetch('https://en.wikipedia.org/w/api.php?action=query&titles=' +String(countyName) +'_County,_Texas&prop=pageimages&format=json&pithumbsize=250&origin=*');
    const data = await response.json();
    const result = JSON.parse(JSON.stringify(data.query.pages));
    const filtered = Object.values(result)
    const finalResult = filtered.find(value => value.title.includes(countyName));
    console.log(finalResult.thumbnail.source);
    return finalResult.thumbnail.source;
  } catch (error) {
    return [];
  }
}

function CountyInstance() {
    const location = useLocation();
    const props = location.state.props;
    const [libraries, setLibraries] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [wikiText, setWikiText] = useState([]);
    const [imageUrl, setImage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            var countyName = props.name.split(" County")[0];
            const fetchedLibraries = await getLibraryProps(countyName);
            setLibraries(fetchedLibraries);
            const fetchedPrograms = await getLiteracyProps(countyName);
            setPrograms(fetchedPrograms);
            console.log(fetchedPrograms);
        }
        fetchData();
    }, []);

    useEffect(() => {
      const fetchPost = async () => {
        var countyName = props.name.split(" County")[0];
        const response = await getImage(countyName);
        setImage(response);
      }
      fetchPost();
    }, []);

    useEffect(() => {
      const fetchPost = async () => {
        var countyName = props.name.split(" County")[0];
        const response = await getWiki(countyName);
        setWikiText(response);
      }
      fetchPost();
    }, []);

    return (
        <div>
            <Image src={imageUrl} width={375} height={400} style={{padding: '30px'}}/>
            <br/>
            <p class="text-middle"><b>County Name:</b> {props.name}</p>
            <p class="text-middle"><b>Population:</b> {props.population}</p>
            <p class="text-middle"><b>Number lacking literacy:</b> {props.num_illiterate}</p>
            <p class="text-middle"><b>Percent lacking literacy:</b> {props.percent_illiterate}%</p>
            <p class="text-middle"><b>Upper bound:</b> {props.upper_bound}%</p>
            <p class="text-middle"><b>Lower bound:</b> {props.lower_bound}%</p>
            {wikiText.map((descrip) => (
              <div style={{backgroundColor: '#73cde6', width: "70%", fontSize: '20px', margin: "auto"}} dangerouslySetInnerHTML={{__html: descrip.extract} }></div>
            ))}
            <p >Source: Wikipedia via <a href ="https://www.mediawiki.org/wiki/API:Main_page">English Wikipedia API</a></p>
            {/* <Button onClick={() => navigate(`/literacy_programs/${literacy_program.id}`, {state: {props: literacy_program}})}> Connection: {literacy_program.name} </Button> */}
            <p class="text-middle" style={{fontSize: '30px'}}><b>Connections:</b></p>
            <Row xs={1} sm={2} md={2} lg={4} className="g-0" style={{marginBottom: '1em', marginTop: '1em', padding: '20px'}}>
                {libraries.map((library) => (
                    <Col key={library.id} style={{ display: 'flex', justifyContent: 'center', padding: '10px'}}>
                        <LibraryCard
                            id={library.id} 
                            imageSrc={"/assets/libraries/library.jpg"} 
                            mapSrc={library.mapSrc} 
                            name={library.name} 
                            county={library.county}
                            city={library.city}
                            square_footage={library.square_footage}
                            audio_items={library.num_audio_items} 
                            video_items = {library.num_video_items}
                        />
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={2} md={2} lg={4} className="g-0" style={{marginBottom: '1em', marginTop: '1em', padding: '20px'}}>
                {programs.map((literacy_program) => (
                    <Col key={literacy_program.id} style={{ display: 'flex', justifyContent: 'center', padding: '10px'}}>
                        <ProgramCard 
                            id={literacy_program.id} 
                            imageSrc={literacy_program.imageSrc} 
                            mapSrc={literacy_program.mapSrc} 
                            name={literacy_program.name} 
                            county={literacy_program.county}
                            address={literacy_program.address} 
                            number={literacy_program.phone_number} 
                            spanish={literacy_program.speak_spanish}>
                        </ProgramCard>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default CountyInstance;