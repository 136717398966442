import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LibraryCard from '../libraries/library_card';
import CountyCard from '../counties/county_card';
import ProgramCard from '../literacy_programs/program_card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Pagination from '../pagination/pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLiteracyProgramsQueryFromEndpoint } from '../literacy_programs/literacy_programs_search';
import { getLibrariesQueryFromEndpoint } from '../libraries/libraries_search';
import { getCountiesQueryFromEndpoint } from '../counties/counties_search';
import './search.css';

function Search() {
    const {query} = useParams();
    const [countiesResult, setCountiesResult] = useState([]);
    const [libraryResult, setLibraryResult] = useState([]);
    const [programsResult, setProgramsResult] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // current page the user is on 
    const [cardsPerPage] = useState(30); // how many cards to display per model

    const location = useLocation();
    //const query = location.state.query;

    useEffect(() => {
        const getCounties = getCountiesQueryFromEndpoint(query);
        getCounties.then(function(result){
            setCountiesResult(result);
        })

        const getPrograms = getLiteracyProgramsQueryFromEndpoint(query);
        getPrograms.then(function(result){
            setProgramsResult(result);
        })

        const getLibraries = getLibrariesQueryFromEndpoint(query);
        getLibraries.then(function(result){
            setLibraryResult(result);
        });
    }, [query]);

    const totalCards = countiesResult.length + libraryResult.length + programsResult.length; 

    const numCounties = countiesResult.length;
    const numPagesCounties = Math.ceil(numCounties / cardsPerPage);
    let firstCountyCardIndex = (currentPage - 1) * cardsPerPage;
    let displayCountyFirstIndex = firstCountyCardIndex;
    const numCurrentCounties = countiesResult.slice(firstCountyCardIndex, firstCountyCardIndex + cardsPerPage).length;
    let lastCountyCardIndex = firstCountyCardIndex + numCurrentCounties;
    let displayCountyLastIndex = lastCountyCardIndex;
    if (numCurrentCounties == 0 || firstCountyCardIndex > numCounties) {
        firstCountyCardIndex = -1;
        lastCountyCardIndex = -1;
        displayCountyFirstIndex = 0;
        displayCountyLastIndex = 0;
    }
  
    const numLibraries = libraryResult.length;
    const numPagesLibraries = Math.ceil(numLibraries / cardsPerPage);
    let firstLibraryCardIndex = (currentPage - 1) * cardsPerPage;
    let displayLibraryFirstIndex = firstLibraryCardIndex;
    const numCurrentLibraries = libraryResult.slice(firstLibraryCardIndex, firstLibraryCardIndex + cardsPerPage).length;
    let lastLibraryCardIndex = firstLibraryCardIndex + numCurrentLibraries;
    let displayLibraryLastIndex = lastLibraryCardIndex;
    if (numCurrentLibraries == 0 || firstLibraryCardIndex > numLibraries) {
        firstLibraryCardIndex = -1;
        lastLibraryCardIndex = -1;
        displayLibraryFirstIndex = 0;
        displayLibraryLastIndex = 0;
    }

    const numPrograms = programsResult.length;
    const numPagesPrograms = Math.ceil(numPrograms / cardsPerPage);
    let firstProgramCardIndex = (currentPage - 1) * cardsPerPage;
    let displayProgramFirstIndex = firstProgramCardIndex;
    const numCurrentPrograms = programsResult.slice(firstProgramCardIndex, firstProgramCardIndex + cardsPerPage).length;
    let lastProgramCardIndex = firstProgramCardIndex + numCurrentPrograms;
    let displayProgramLastIndex = lastProgramCardIndex;
    if (numCurrentPrograms == 0 || firstProgramCardIndex > numPrograms) {
        firstProgramCardIndex = -1;
        lastProgramCardIndex = -1;
        displayProgramFirstIndex = 0;
        displayProgramLastIndex = 0;
    }

    const numPages = Math.max(numPagesCounties, numPagesLibraries, numPagesPrograms);

    const currentCounties = countiesResult.slice(firstCountyCardIndex, lastCountyCardIndex + 1);
    const currentLibraries = libraryResult.slice(firstLibraryCardIndex, lastLibraryCardIndex + 1);
    const currentPrograms = programsResult.slice(firstProgramCardIndex, lastProgramCardIndex + 1);
    
    return (
        <div style={{margin: 'auto', overflow: 'hidden'}}>
            <div style={{backgroundColor: '#293241', color: 'white', fontSize:35, fontWeight:'bold', padding:'25px'}}>
                Search
            </div>
            <div style={{fontSize: '25px', fontWeight: 'bold', color: '#293241'}}>
                {totalCards} Results for "{query}": {numCounties} counties, {numLibraries} libraries, and {numPrograms} programs
            </div>
            <Pagination
                numPages={numPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <div style={{fontSize: '20px', fontWeight: 'bold', color: '#293241'}}>
                Displaying {displayCountyFirstIndex} - {displayCountyLastIndex} out of {numCounties} Total Counties:
            </div>
            <Container fluid style={{justifyContent:'center'}}>
                <Row xs={1} sm={2} md={3} lg={4} className="justify-content-md-center" style={{padding: '10px'}}>
                    {currentCounties.map((county, id) => (
                        <Col key={id} style={{ display: 'flex', justifyContent: 'center', padding: '10px'}}>
                            <CountyCard cardQuery={query} id={county.id} imageSrc={""} graphSrc={""} name={county.name} population={county.population} num_illiterate={getNumIlliterate(county.percent_literacy_lacking, county.population)} 
                            percent_illiterate={county.percent_literacy_lacking} upper_bound={county.literacy_upper_bound} lower_bound={county.literacy_lower_bound}
                            embed = {""}></CountyCard>
                        </Col>
                    ))}
                </Row>
            </Container>
            <div style={{fontSize: '20px', fontWeight: 'bold', color: '#293241'}}>
            Displaying {displayLibraryFirstIndex} - {displayLibraryLastIndex} out of {numLibraries} Total Libraries:
            </div>
            <Container fluid style={{justifyContent:'center'}}>
                <Row xs={1} sm={2} md={3} lg={4} className="justify-content-md-center" style={{padding: '10px'}}>
                    {currentLibraries.map((library, id) => (
                        <Col key={id} style={{ display: 'flex', justifyContent: 'center', padding: '10px'}}>
                            <LibraryCard cardQuery={query} id={library.id} imageSrc={"/assets/libraries/library.jpg"} name={library.name} city={library.city} county={library.county} 
                            square_footage={library.square_footage} audio_items={library.num_audio_items} video_items={library.num_video_items} mapSrc = {""}
                            address = {library.address}>
                            </LibraryCard>
                        </Col>
                    ))}
                </Row> 
            </Container>
            <div style={{fontSize: '20px', fontWeight: 'bold', color: '#293241'}}>
                Displaying {displayProgramFirstIndex} - {displayProgramLastIndex} out of {numPrograms} Total Programs:
            </div>
            <Container fluid style={{justifyContent: 'center'}}>
                <Row xs={1} sm={2} md={3} lg={4} className="justify-content-md-center" style={{ padding: '10px'}}>
                    {currentPrograms.map((program, id) => (
                    <Col key={id} style={{ display: 'flex', justifyContent: 'center', padding: '10px'}}>
                        {/* {()=>setImage(getImageInfo(program.name))}; */}
                        <ProgramCard cardQuery={query} imageSrc={"assets/programs/literacy-for-texas.png"} name={program.name} county={program.county} library={""} address={program.address}
                        spanish={program.speak_spanish} id={program.id} number={program.phone_number} mapSrc={""}>
                        </ProgramCard>
                    </Col>
                    ))}
                </Row>
            </Container>
            <Pagination
                numPages={numPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
      </div>
  
    )
}

export function getNumIlliterate(percent, total){
    return Math.round(total * percent/100)
}

export default Search; 