import React, {useState, useEffect} from 'react';
import { useMediaQuery } from 'react-responsive';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import { json } from 'react-router-dom';


function Critiques() {
  const navigate = useNavigate();
    return (
        <div style={{margin: 'auto', overflow: 'hidden'}}>
            <div style={{marginBottom: '1em', color: '#293241'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px'}}>What did we do well?</div>
                <div style={{marginLeft: '100px', marginRight:'100px'}}>We split up the work very well. Each phase we split up the tasks evenly. We also communicated
                very well, and were consistently dicussing in our group project for the project, as well as meeting roughly once a week.</div> 
            </div>
            <div style={{marginBottom: '1em', color: '#293241'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px'}}>What did we learn?</div>
                <div style={{marginLeft: '100px', marginRight:'100px'}}>We learned a lot about web development over the course of this project. On, the backend we learned how to use Postman, 
                create an API we can call, call from the API, create a SQL database, and use the D3 library to create visualizations. On the frontend, we learned to use React, learned how to create
                frontend tests using Mocha and Jest, and learned a lot about how to use Flexbox. We create model grids and instance pages. We also learned how implement searching and sorting through the
                API, and to scrape data from other APIs like the Wikipedia API.</div> 
            </div>
            <div style={{marginBottom: '1em', color: '#293241'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px'}}>What did we teach each other?</div>
                <div style={{marginLeft: '100px', marginRight:'100px'}}>We were very collaborative in the group, and learned a lot. A few things we taught each other was how to make API
                requests in React, how to use useEffect from the React library to detect changes to one attribute or multiple attributes, and how to create a dropdown form to select sorting.</div> 
            </div>
            <div style={{marginBottom: '1em', color: '#293241'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px'}}>What can we do better?</div>
                <div style={{marginLeft: '100px', marginRight:'100px'}}>I think a couple things we could do better if we have more time are to do some more front-end optimizations and trying out 
                different fonts, or changing the formatting on some of the grid boxes, or making the graphs look cleaner. We could also vary up the images more for literacy programs and library
                models, since they're all sourced from the county Wikipedia page, so there are some repetitions. </div> 
            </div>
            <div style={{marginBottom: '1em', color: '#293241'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px'}}>What effect did the peer reviews have?</div>
                <div style={{marginLeft: '100px', marginRight:'100px'}}>The peer reviews were a helpful way to reflect on whether we worked together well and contributed equally to the team. Thankfully, we worked 
                together fairly well from the beginning, so we didn't have to change much of our process, but it was nice to see that we were treating each other well, and felt the work was divided fairly. The comments 
                were also a helpful way to keep track of the work we did and see some nice feedback from our group partners.</div> 
            </div>
            <div style={{marginBottom: '1em', color: '#293241'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px'}}>What puzzles us?</div>
                <div style={{marginLeft: '100px', marginRight:'100px'}}>I think things that puzzle us for the future are creating more interactivity in websites, such as interactive graphs or displays. As we were working on the website, some things 
                we found the most puzzling or difficult were setting up sorting and searching functionality, pagination, and scraping from the Wikipedia image API.</div> 
            </div>
        </div>
    )
}

export default Critiques;