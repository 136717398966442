import React, { useState, useEffect } from 'react';
import AboutCard from './about_card';
import ToolCard from './tool_card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { getCommits, getIssues } from "../../services/gitlab_service";
import { people_information, tools_information } from './about_info';
import { Link } from 'react-router-dom';

function AboutMe() {

    useEffect(() => {
        const getTotalCommitsIssues = async(emails, ids) => {
            const commits = await getCommits();
            const issues = await getIssues();
            console.log(ids);
            var userToCommits = new Map();
            var userToIssues = new Map();
            emails.forEach((email) => {
                userToCommits.set(email, 0);
            });
            ids.forEach((id) => {
                userToIssues.set(id, 0);
            });
            commits.forEach((commit) => {
                const member = emails.find((email) => email === commit.committer_email)
                if (member) {
                    userToCommits.set(commit.committer_email, userToCommits.get(commit.committer_email) + 1);
                }
            });
            console.log(userToIssues);
            issues.forEach((issue) => {
                console.log(issue.author.username);
                const member = ids.find((id) => id === issue.author.username)
                if (member) {
                    userToIssues.set(issue.author.username, userToIssues.get(issue.author.username) + 1);
                }
            });
            console.log(userToIssues);
            setCommitsInfo(userToCommits);
            setIssuesInfo(userToIssues);
        };
        const emails = [];
        const ids = [];
        people_information.forEach((person) => {
            emails.push(person.email);
            ids.push(person.gitlab);
        }); 
        getTotalCommitsIssues(emails, ids);
    }, []);


    const [peopleInfo, setPeopleInfo] = useState(people_information);
    const [toolsInfo, setToolsInfo] = useState(tools_information);
    const [commitsInfo, setCommitsInfo] = useState(new Map());
    const [issuesInfo, setIssuesInfo] = useState(new Map());


    return (
        <div style={{margin: 'auto', overflow: 'hidden', marginBottom: '1em'}}>
            <div style={{backgroundColor: '#293241', color: 'white', fontSize:35, fontWeight:'bold', padding:'25px'}}>
                About us
            </div>
            <div style={{marginBottom: '1em', color: '#293241'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px'}}>Our Purpose:</div>
                <div style={{marginLeft: '100px', marginRight:'100px'}}>Our mission is to provide resources for Texan adults lacking proper literacy. We aim to direct these
                individuals to beneficial information so that they can attempt to gain literacy.</div> 
                <a href="https://www.youtube.com/watch?v=lPyRoR9QBS4&feature=youtu.be">
                    <Button className="about-button"><b>Illiteracy Pecha Kucha Video</b></Button>
                </a>
            </div>
            <div style={{marginBottom: '1em', color: '#293241'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px'}}>Where is our data from?</div>
                <div style={{marginLeft: '100px', marginRight:'100px'}}> Our data ranges from a variety of sources, including government issued datasets, statistics 
                from the texas government website, and websites of non profit organizations. We are retrieving
                data about counties and their literacy rates, free adult literacy programs, and Texas public libraries.</div>
                <a href="https://nces.ed.gov/naal/estimates/stateestimates.aspx">
                    <Button className="about-button"><b>County Data Source 1</b></Button>
                </a>
                <br></br>
                <a href="https://data.amerigeoss.org/de/dataset/piaac-county-indicators-of-adult-literacy-and-numeracy/resource/5fc42389-5fc2-4b8e-9916-1d9d4d3eb86d?inner_span=True">
                    <Button className="about-button"><b>County Data Source 2</b></Button>
                </a>
                <br></br>
                <a href="https://www.mediawiki.org/wiki/API:Main_page">
                    <Button className="about-button"><b>County Data Source 3: RESTFUL API</b></Button>
                </a>
                <br></br>
                <a href="https://www.literacytexas.org/connect/find-a-program/">
                    <Button className="about-button"><b>Adult Literacy Programs Data Source</b></Button>
                </a>
                <br></br>
                <a href="https://www.tsl.texas.gov/ldn/statistics">
                    <Button className="about-button"><b>Library Data Source 1</b></Button></a>
                <br></br>
                <a href="https://developers.google.com/maps/documentation/embed/quickstart#enable-api-sdk">
                    <Button className="about-button"><b>Library and Literacy Programs Map Source: RESTful API</b></Button>
                </a>
            </div>
            <div style={{marginBottom: '1em', color: '#293241'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px'}}>Integrating Data:</div>
                <div style={{marginLeft: '100px', marginRight:'100px'}}> Integrating disparate data was a really interesting experience. It took us a
               lot of work to figure out the general documentation of instance pages and the link,
               but it was interesting to use our attributes to help search for other information.
               Having all the data sources together made us a lot more hopeful about literacy aid in Texas.
               Although a lot of Texans across the state struggle with illiteracy according to our county data,
               the library programs and libraries data made us realize that there are a lot of easily accessible resources
               available to those struggling. </div>
            </div>
            <div style={{marginBottom: '1em', color: '#293241'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px'}}>Where to find our API:</div>
                <Link to="https://documenter.getpostman.com/view/32906967/2sA2r6WPJ8">
                <Button className="about-button"><b>Postman API</b></Button></Link>
            </div>
            <Container fluid style={{display:'flex', justifyContent:'center'}}>
            <Row xs={1} sm={1} md={2} lg={3} xl={5} className="justify-content-md-center" style={{marginBottom: '1em', padding: '10px'}}>
                {Array.from(peopleInfo).slice(0, 3).map((person, idx) => (
                    <Col key={idx} style={{ display: 'flex', justifyContent: 'center', padding: '10px', width: '400px'}}>
                        <AboutCard imageSrc={person.imageSrc} name={person.name} bio={person.bio} gitlab={person.gitlab} email={person.email}
                        role={person.role} commits={commitsInfo.get(person.email)} issues={issuesInfo.get(person.gitlab)} tests={person.tests}></AboutCard>
                    </Col>
                ))}
            </Row>
            </Container>
            <Container fluid style={{display:'flex', justifyContent:'center'}}>
            <Row xs={1} sm={1} md={2} lg={3} xl={5} className="justify-content-md-center" style={{padding: '10px'}}>
                {Array.from(peopleInfo).slice(3, 5).map((person, idx) => (
                    <Col key={idx} style={{ display: 'flex', justifyContent: 'center', padding: '10px', width: '400px'}}>
                        <AboutCard imageSrc={person.imageSrc} name={person.name} bio={person.bio} gitlab={person.gitlab} email={person.email}
                        role={person.role} commits={commitsInfo.get(person.email)} issues={issuesInfo.get(person.gitlab)} tests={person.tests}></AboutCard>
                    </Col>
                ))}
            </Row>
            </Container>
            <div sm={5} style={{padding: '20px'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px', padding: '20px', color: '#293241'}}>Tools Used:</div>
                <Row className="justify-content-md-center">
                    {Array.from(toolsInfo).map((tool, idx) => (
                        <Col key={idx} style={{display: 'flex', justifyContent: 'center', padding: '10px'}} >
                            <ToolCard imageSrc={tool.imageSrc} name={tool.name}></ToolCard>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    )
}

export default AboutMe;
