import { Link, useLocation } from 'react-router-dom';
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/esm/Button';
import ProgramCard from '../literacy_programs/program_card';
import CountyCard from '../counties/county_card';
import { useNavigate } from 'react-router-dom';
// import { county_information } from '../counties/county_info';
// import { program_information } from '../literacy_programs/program_info';
import { useState, useEffect } from 'react';
import { getNumIlliterate } from '../counties/counties';

async function getCountyProps(countyName) {
    try {
        const response = await fetch('https://cs373-backend.literatetx.me/get_counties');
        const data = await response.json();
        const filteredCounties = data.result.filter(county => county.name.includes(countyName));
        return filteredCounties;
    } catch (error) {
        return [];
    }
}

async function getLiteracyProps(countyName) {
    try {
        const response = await fetch('https://cs373-backend.literatetx.me/get_programs');
        const data = await response.json();
        const filteredPrograms = data.result.filter(program => program.county === countyName);
        return filteredPrograms;
    } catch (error) {
        return [];
    }
}

async function getImage(countyName) {
  console.log(countyName)
  try {
    const response = await fetch('https://en.wikipedia.org/w/api.php?action=query&titles=' +String(countyName) +'_County,_Texas&prop=pageimages&format=json&pithumbsize=250&origin=*');
    const data = await response.json();
    const result = JSON.parse(JSON.stringify(data.query.pages));
    const filtered = Object.values(result)
    const finalResult = filtered.find(value => value.title.includes(countyName));
    console.log(finalResult.thumbnail.source);
    return finalResult.thumbnail.source;
  } catch (error) {
    return [];
  }
}

async function makeURL(address) {
  const formatted = address.replace(/\s/g,'+')
  var url = "https://www.google.com/maps/embed/v1/place?key=AIzaSyDiSH6IyCrFKQLNvAOsTDVbPDyLn7qZZL8&q=" + String(formatted) + ""
  return url;
}

function LibraryInstance() {
    const location = useLocation();
    const props = location.state.props;
    const [counties, setCounties] = useState([]);
    const [programs, setPrograms] = useState([]);
    const[mapUrl, setMapURL] = useState([]);
    const [imageUrl, setImage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            const fetchedCounties = await getCountyProps(props.county);
            setCounties(fetchedCounties);

            const fetchedPrograms = await getLiteracyProps(props.county);
            setPrograms(fetchedPrograms);

            const url = await makeURL(props.name);
            setMapURL(url);
        }
        fetchData();
    }, []);

    useEffect(() => {
      const fetchPost = async () => {
        var countyName = props.county;
        const response = await getImage(countyName);
        setImage(response);
      }
      fetchPost();
    }, []);

    return (
        <div>
            <Image src={imageUrl} width={375} height={400} style={{padding: '30px'}}/>
            <br/>
            <p class="text-middle"><b>Library Name:</b> {props.name}</p>
            <p class="text-middle"><b>City:</b> {props.city}</p>
            <p class="text-middle"><b>County:</b> {props.county}</p>
            <p class="text-middle"><b>Square Footage:</b> {props.square_footage}</p>
            <p class="text-middle"><b>Audio Items:</b> {props.audio_items}</p>
            <p class="text-middle"><b>Video Items:</b> {props.video_items}</p>
            <p class="text-middle"><b>Address:</b> {props.address}</p>
            {/* <Button onClick={() => navigate(`/counties/${county.id}`, {state: {props: county}})}> Connection: {props.county} </Button> */}
            <iframe
              title="myBook"
              src={mapUrl}
              height="600px"
              width="60%"
            ></iframe>
            <p class="text-middle" style={{fontSize: '30px'}}><b>Connections:</b></p>
            <Row xs={1} sm={2} md={2} lg={4} className="g-0" style={{marginBottom: '1em', marginTop: '1em', padding: '20px'}}>
                {counties.map((county) => (
                    <Col key={county.id} style={{display: 'flex', justifyContent: 'center', padding: '10px'}}>
                        <CountyCard
                            id={county.id}
                            name={county.name}
                            population={county.population}
                            num_illiterate={getNumIlliterate(county.percent_literacy_lacking, county.population)}
                            imageSrc={county.imageSrc}
                            graphSrc={county.graphSrc}
                            embed={county.embed}
                            percent_illiterate={county.percent_literacy_lacking}
                            upper_bound={county.literacy_upper_bound}
                            lower_bound={county.literacy_lower_bound}
                        />
                    </Col>
                ))}
                </Row>
                <Row xs={1} sm={2} md={2} lg={4} className="g-0" style={{marginBottom: '1em', marginTop: '1em', padding: '20px'}}>
                {programs.map((literacy_program) => (
                    <Col key={literacy_program.id} style={{display: 'flex', justifyContent: 'center', padding: '10px'}}>
                        <ProgramCard 
                            id={literacy_program.id} 
                            imageSrc={"/assets/programs/literacy-for-texas.png"} 
                            mapSrc={literacy_program.mapSrc} 
                            name={literacy_program.name} 
                            county={literacy_program.county}
                            address={literacy_program.address} 
                            number={literacy_program.phone_number} 
                            spanish={literacy_program.speak_spanish}>
                        </ProgramCard>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default LibraryInstance;