import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CountyCard from './county_card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container';
import Pagination from '../pagination/pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { sort } from 'd3';

function CountiesSearch() {
    const [countiesResult, setCountiesResult] = useState([]);
    const [sort_category, setCategory] = useState("");
    const [sort_order, setOrder] = useState("");
    const {query} = useParams();
    const location = useLocation();
    // const query = location.state.query;

    useEffect(() => {
        const getCounties = getCountiesQueryFromEndpoint(query);
        const order = sort_order ? sort_order : '&asc';
        getCounties.then(function(result){
            if(sort_category === "population") {
              if(order === "&asc") {
                result.sort((a, b)=> a.population > b.population ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.population < b.population ? 1: -1)
              }
            } else if(sort_category === "percent_literacy_lacking") {
              if(order === "&asc") {
                result.sort((a, b)=> a.percent_literacy_lacking > b.percent_literacy_lacking ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.percent_literacy_lacking < b.percent_literacy_lacking ? 1: -1)
              }
            } else if(sort_category === "name") {
              if(order === "&asc") {
                result.sort((a, b)=> a.name > b.name ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.name < b.name ? 1: -1)
              }
            } else if(sort_category === "literacy_lower_bound") {
              if(order === "&asc") {
                result.sort((a, b)=> a.literacy_lower_bound > b.literacy_lower_bound ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.literacy_lower_bound < b.literacy_lower_bound ? 1: -1)
              }
            } else if(sort_category === "literacy_upper_bound") {
              if(order === "&asc") {
                result.sort((a, b)=> a.literacy_upper_bound > b.literacy_upper_bound ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.literacy_upper_bound < b.literacy_upper_bound ? 1: -1)
              }
            }
            setCountiesResult(result);
        });
    }, [query, sort_category, sort_order]);

    const handleCategoryChange = (e) => {
      setCategory(e.target.value);
      setOrder('');
    };

    const [currentPage, setCurrentPage] = useState(1); // current page the user is on 
    const [cardsPerPage] = useState(15);
    let indexOfLastCard = currentPage * cardsPerPage;
    let indexOfFirstCard = indexOfLastCard - cardsPerPage; 
    
    const numCounties = countiesResult.length;
    // total number of pages 
    const numPages = Math.ceil(numCounties / cardsPerPage);

    let currentCounties = countiesResult.slice(indexOfFirstCard, indexOfLastCard);
    if (currentCounties.length < cardsPerPage) {
        indexOfLastCard = indexOfFirstCard + currentCounties.length;
    }
    
    return (
        <div style={{margin: 'auto', overflow: 'hidden'}}>
            <div style={{backgroundColor: '#293241', color: 'white', fontSize:35, fontWeight:'bold', padding:'25px'}}>
                Counties
            </div>
            <div className="mb-3" sm={4} style={{ display: 'flex', justifyContent: 'center', padding: '25px'}}>
                    <Form.Group controlId="sort_category">
                        <Form.Select value={sort_category} onChange={handleCategoryChange}>
                        <option value="">Sort By</option>
                        <option value="population">Population</option>
                        <option value="percent_literacy_lacking">Percent Lacking Literacy</option>
                        <option value="name">Name</option>
                        <option value="literacy_lower_bound">Lower bound</option>
                        <option value="literacy_upper_bound">Upper Bound</option>
                        </Form.Select>
                    </Form.Group>

                    {sort_category && (
                        <Form.Group controlId="sort_order" style={{display: 'flex', alignItems: 'center', paddingLeft: '25px'}}>
                            <Form.Select value={sort_order} onChange={(e) => setOrder(e.target.value)}>
                                <option value={`&asc`}>Ascending</option>
                                <option value={`&desc`}>Descending</option>
                            </Form.Select>
                        </Form.Group>
                    )}
              </div>
            <div style={{fontSize: '25px', fontWeight: 'bold', color: '#293241'}}>
                {numCounties} Results for "{query}"
            </div>
            <Pagination
                numPages={numPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <div style={{fontSize: '20px', fontWeight: 'bold', color: '#293241'}}>
                Displaying {indexOfFirstCard} - {indexOfLastCard} out of {numCounties} Total Counties:
            </div>
            <Container fluid style={{justifyContent:'center'}}>
                <Row xs={1} sm={2} md={3} lg={4} className="justify-content-md-center" style={{padding: '10px'}}>
                    {currentCounties.map((county, id) => (
                        <Col key={id} style={{ display: 'flex', justifyContent: 'center', padding: '10px'}}>
                            <CountyCard cardQuery={query} id={county.id} imageSrc={""} graphSrc={""} name={county.name} population={county.population} num_illiterate={getNumIlliterate(county.percent_literacy_lacking, county.population)} 
                            percent_illiterate={county.percent_literacy_lacking} upper_bound={county.literacy_upper_bound} lower_bound={county.literacy_lower_bound} highlighted={query}
                            embed = {""}></CountyCard>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Pagination
                numPages={numPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
      </div>
  
    )
}

export function getNumIlliterate(percent, total){
    return Math.round(total * percent/100)
}

export function getCountiesQueryFromEndpoint(query) {
    var addedStr = query.split(' ').join('_');
    console.log(addedStr)
    return fetch(`https://cs373-backend.literatetx.me/search/counties/${addedStr}`)
        .then(response => response.json())
        .then((jsonCounties) => {
            return jsonCounties.data;
        })
        .catch((error) => {
            console.error(error);
        });
}


export default CountiesSearch; 