import React, {useState, useEffect} from 'react';
import { useMediaQuery } from 'react-responsive';
import './home.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import { json } from 'react-router-dom';

const Example = () => {
  const isDesktopOrLaptop = useMediaQuery({
  query: '(min-width: 1224px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
}


function Home() {
    const [wikiText, setWikiText] = useState([]);
    const location = useLocation();
    //const parser = new DOMParser(), dom = parser.parseFromString(text, "text/html");
    // GET with fetch API
    return (
        <div style={{margin: 'auto', overflow: 'hidden'}}>
            <div style={{color: '#293241', fontSize: '35px', padding:'20px', textAlign: 'center'}}>
                   <bold> <em> Welcome to LiterateTX </em> </bold>
                <p style={{textAlign: 'center', fontSize: '20px', padding: '15px', flex: 1, alignSelf:'center'}}>Texas has the third lowest adult literacy rate in the nation at 71.8%.
                            Illiteracy leads to fewer employment opportunities, lower incomes, and a higher likelihood
                            of committing crimes. It also hurts future generations, as parents who are illiterate or 
                            have a low level of literacy often raise children who also struggle with literacy and education
                            as a whole.
                            LiterateTX aims to raise awareness about the prevalence of Texan adults who
                            struggle with illiteracy, and provide illiterate and low-literacy adults
                            with resources to improve their literacy skills.<br></br>
                </p>
            </div>
            <div style={{marginBottom: '1em', color: '#293241'}}>
                <div class = "about-header" style = {{display: 'flex'}}>
                <Container fluid style={{justifyContent:'center'}}>
                <Row xs={1} sm={2} md={3} lg={4} className="justify-content-md-center" style={{padding: '10px', fontSize: '20px'}}>
                    <Col className='home-card'>
                        <p style={{textAlign: 'center', maxWidth: '800px', paddingLeft: '35px', paddingRight: '30px', flex: 1, alignSelf:'center'}}>
                            <img src = "assets/about-icons/map-icon.png" style={{flex: 1, width: '13%', height: '13%', paddingRight: '5px'}}></img>
                            <b>Counties </b>
                                <br></br> <br></br>Browse counties located in Texas. Learn basic demographic information as well as literacy statistics
                                regarding each county. We also provide data on the proportion and range of illiterate adults in the  population of each county. 
                                Find libraries and literacy programs located in these counties. <br></br>
                                <div className='navbar-brand' style={{color: 'white', margin: '2px', background: '#23adad', border: '3px solid #23adad', borderRadius: '5px', marginTop: '15px'}}>
                                    <Link to="/counties" style={{color: 'white', textDecoration: 'none'}}>
                                        Learn More
                                    </Link>
                                </div>
                        </p>
                    </Col>
                    <Col className='home-card'>
                        <p style={{textAlign: 'center', maxWidth: '800px', paddingLeft: '35px', paddingRight: '30px', flex: 1, alignSelf:'center'}}>
                            <img src = "assets/about-icons/pencil-icon.png" style={{flex: 1, width: '13%', height: '13%', paddingLeft: '5px'}}></img>
                            <b> Literacy Programs </b> 
                                <br></br> <br></br>Search programs and resources aimed at improving literacy. Learn about 
                                the counties that each program is based in, its address, phone number, and whether or not they provide additional support for Spanish speakers. 
                                Find libraries located in the counties that these programs serve. <br></br>
                                <div className='navbar-brand' style={{color: 'white', margin: '2px', background: '#23adad', border: '3px solid #23adad', borderRadius: '5px', marginTop: '15px'}}>
                                    <Link to="/literacy_programs" style={{color: 'white', textDecoration: 'none'}}>
                                        Learn More
                                    </Link>
                                </div>
                        </p>
                    </Col>
                    <Col className='home-card'>
                        <p style={{textAlign: 'center', maxWidth: '800px', paddingLeft: '35px', paddingRight: '30px', flex: 1, alignSelf:'center'}}>
                        <img src = "assets/about-icons/library-icon.png" style={{flex: 1, width: '13%', height: '13%', paddingRight: '5px'}}></img>
                        <b>Libraries </b>
                            <br></br> <br></br>Find public libraries in Texas where low-literacy adults can access reading material to improve their literacy. Find
                            the library's location, size, and the number of audio and video items that are available for checkout. 
                            Find programs and resources found within the same city/county as the library. 
                            <div className='navbar-brand' style={{color: 'white', margin: '2px', background: '#23adad', border: '3px solid #23adad', borderRadius: '5px', marginTop: '15px'}}>
                                <Link to="/libraries" style={{color: 'white', textDecoration: 'none'}}>
                                    Learn More
                                </Link>
                            </div>
                        </p>
                    </Col>

                </Row>
            </Container>
            </div>
            <br></br>
            <iframe className='video' src="https://www.youtube.com/embed/elDY1fX7xaU?si=2wRZKXEYmksIoAys" title="YouTube video player" 
                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{height: '50%', width: '50%'}}>
            </iframe>
        </div>
        </div>
    )
}

export default Home;