import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import './about.css';

function ToolCard(props) {
    const [isVisible, setIsVisible] = useState(false);
    const [animationPlayed, setAnimationPlayed] = useState(false);
    const cardRef = React.useRef();

    useEffect(() => {
        const handleScroll = () => {
            const elementTop = cardRef.current.getBoundingClientRect().top;
            const isVisible = elementTop < window.innerHeight - 100;
            setIsVisible(isVisible);
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    useEffect(() => {
        if (isVisible && !animationPlayed) {
            setAnimationPlayed(true);
        }
    }, [isVisible, animationPlayed]);

    return (
        <Card className={`tool-card ${isVisible || animationPlayed ? 'visible' : ''}`} ref={cardRef}>
            <Card.Img as={Image} variant="top" width='100rem' height='150rem' src={props.imageSrc}/>
            <Card.Body>
                <Card.Title style={{color: 'white'}}>{props.name}</Card.Title>
            </Card.Body>
        </Card>
    )
}

export default ToolCard;
