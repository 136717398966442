import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LibraryCard from './library_card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container';
import Pagination from '../pagination/pagination';
import { useLocation, useNavigate } from 'react-router-dom';

function LibrariesSearch() {
    const [libraryResult, setLibraryResult] = useState([]);
    const [sort_category, setCategory] = useState("");
    const [sort_order, setOrder] = useState("");
    const {query} = useParams();
    const location = useLocation();
//    const query = location.state.query;

    useEffect(() => {
        const getLibraries = getLibrariesQueryFromEndpoint(query);
        const order = sort_order ? sort_order : '&asc';
        getLibraries.then(function(result){
            if(sort_category === "county") {
              if(order === "&asc") {
                result.sort((a, b)=> a.population > b.population ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.population < b.population ? 1: -1)
              }
            } else if(sort_category === "name") {
              if(order === "&asc") {
                result.sort((a, b)=> a.name > b.name ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.name < b.name ? 1: -1)
              }
            } else if(sort_category === "city") {
              if(order === "&asc") {
                result.sort((a, b)=> a.city > b.city ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.city < b.city ? 1: -1)
              }
            } else if(sort_category === "num_audio_items") {
              if(order === "&asc") {
                result.sort((a, b)=> a.num_audio_items > b.num_audio_items ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.num_audio_items < b.num_audio_items ? 1: -1)
              }
            } else if(sort_category === "num_video_items") {
              if(order === "&asc") {
                result.sort((a, b)=> a.num_video_items > b.num_video_items ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.num_video_items < b.num_video_items ? 1: -1)
              }
            } else if(sort_category === "square_footage") {
              if(order === "&asc") {
                result.sort((a, b)=> a.square_footage > b.square_footage ? 1: -1)
              } else if(order === "&desc") {
                result.sort((a, b)=> a.square_footage < b.square_footage ? 1: -1)
              }
            }
            setLibraryResult(result);
        });
    }, [query, sort_category, sort_order]);
    
    const handleCategoryChange = (e) => {
      setCategory(e.target.value);
      setOrder('');
    };

    const [currentPage, setCurrentPage] = useState(1); // current page the user is on 
    const [cardsPerPage] = useState(15);
    let indexOfLastCard = currentPage * cardsPerPage;
    let indexOfFirstCard = indexOfLastCard - cardsPerPage; 
    
    const numLibraries = libraryResult.length;
    // total number of pages 
    const numPages = Math.ceil(libraryResult.length / cardsPerPage);
    
    let currentLibraries =  libraryResult.slice(indexOfFirstCard, indexOfLastCard);
    if (currentLibraries.length < cardsPerPage) {
        indexOfLastCard = indexOfFirstCard + currentLibraries.length;
    }
    return (
        <div style={{margin: 'auto', overflow: 'hidden'}}>
            <div style={{backgroundColor: '#293241', color: 'white', fontSize:35, fontWeight:'bold', padding:'25px'}}>
                Libraries
            </div>
            <div className="mb-3" sm={4} style={{ display: 'flex', justifyContent: 'center', padding: '25px'}}>
                    <Form.Group controlId="sort_category">
                        <Form.Select value={sort_category} onChange={handleCategoryChange}>
                            <option value="">Sort By</option>
                            <option value="county">County</option>
                            <option value="name">Name</option>
                            <option value="city">City</option>
                            <option value="num_audio_items">Num Audio Items</option>
                            <option value="num_video_items">Num Video Items</option>
                            <option value="square_footage">Square Feet</option>
                        </Form.Select>
                    </Form.Group>

                    {sort_category && (
                        <Form.Group controlId="sort_order" style={{display: 'flex', alignItems: 'center', paddingLeft: '25px'}}>
                            <Form.Select value={sort_order} onChange={(e) => setOrder(e.target.value)}>
                                <option value={`&asc`}>Ascending</option>
                                <option value={`&desc`}>Descending</option>
                            </Form.Select>
                        </Form.Group>
                    )}
                </div>
            <div style={{fontSize: '25px', fontWeight: 'bold', color: '#293241'}}>
                {numLibraries} Results for "{query}"
            </div>
            <Pagination
                numPages={numPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <div style={{fontSize: '20px', fontWeight: 'bold', color: '#293241'}}>
                Displaying {indexOfFirstCard} - {indexOfLastCard} out of {numLibraries} Total Libraries:
            </div>      
            <Container fluid style={{justifyContent:'center'}}>
                <Row xs={1} sm={2} md={3} lg={4} className="justify-content-md-center" style={{padding: '10px'}}>
                    {currentLibraries.map((library, id) => (
                        <Col key={id} style={{ display: 'flex', justifyContent: 'center', padding: '10px'}}>
                            <LibraryCard cardQuery={query} id={library.id} imageSrc={"/assets/libraries/library.jpg"} name={library.name} city={library.city} county={library.county} 
                            square_footage={library.square_footage} audio_items={library.num_audio_items} video_items={library.num_video_items} mapSrc = {""}
                            address = {library.address}>
                            </LibraryCard>
                        </Col>
                    ))}
                </Row> 
            </Container>
            <Pagination
                numPages={numPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
      </div>
  
    )
}


export function getLibrariesQueryFromEndpoint(query) {
    var addedStr = query.split(' ').join('_');
    return fetch(`https://cs373-backend.literatetx.me/search/libraries/${addedStr}`)
        .then(response => response.json())
        .then((jsonLibraries) => {
            return jsonLibraries.data;
        })
        .catch((error) => {
            console.error(error);
        })
}


export default LibrariesSearch; 