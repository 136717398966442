import React, { useState, useEffect } from 'react';
import LibrariesCard from './library_card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { library_information } from './library_info';
import Pagination from '../pagination/pagination';
import {Link, useNavigate} from 'react-router-dom';
import { FaSearch } from "react-icons/fa";

function Libraries() {
    const [libraryResult, setlibraryResult] = useState([])
    const getLibraries = getLibrariesFromEndpoint("");
    const [sort_category, setCategory] = useState("");
    const [sort_order, setOrder] = useState("");
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
        setOrder('');
    };

    const handleChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            navigateToSearch();
        }
    };

    const navigateToSearch = () => {
        if (searchText.trim() !== '') {
            window.location.href = `/libraries_search/${searchText}`;
        }
    };

    useEffect(() => {
        console.log(sort_category);
        console.log(sort_order);
        const order = sort_order ? sort_order : '&asc';
        const apiParam = sort_category ? '?sort=' + sort_category + order : '';
        console.log(apiParam);
        getLibrariesFromEndpoint(apiParam).then(function(result){
            setlibraryResult(result)
        }) 
    }, [sort_category, sort_order]);

    const [currentPage, setCurrentPage] = useState(1); // current page the user is on 
    const [cardsPerPage] = useState(30);
    let indexOfLastCard = currentPage * cardsPerPage;
    let indexOfFirstCard = indexOfLastCard - cardsPerPage; 

    // total number of pages 
    const numPages = Math.ceil(libraryResult.length / cardsPerPage);
    // cards to be displayed on the current page
    const currentLibraries = libraryResult.slice(indexOfFirstCard, indexOfLastCard);
    if (currentLibraries.length < cardsPerPage) {
        indexOfLastCard = indexOfFirstCard + currentLibraries.length;
    }

    return (
        <div style={{margin: 'auto', overflow: 'hidden'}}>
           <div style={{backgroundColor: '#293241', color: 'white', fontSize:35, fontWeight:'bold', padding:'25px'}}>
                Libraries
            </div>
            <div className="search-and-sort-container" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                <div className='input-box-model'>
                    <FaSearch id="search-libraries-icon" onClick={navigateToSearch}/>
                    <input type="search" 
                        name="search-libraries" 
                        id="search-libraries"
                        data-testid="search-libraries"
                        className="search-input" 
                        placeholder="Search libraries..." 
                        onKeyUp={handleKeyPress}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3" sm={4} style={{display: 'flex', padding: '25px'}}>
                    <Form.Group controlId="sort_category" style={{display: 'flex', alignItems: 'center'}}>
                        <Form.Select value={sort_category} onChange={handleCategoryChange}>
                            <option value="">Sort By</option>
                            <option value="county">County</option>
                            <option value="name">Name</option>
                            <option value="city">City</option>
                            <option value="num_audio_items">Num Audio Items</option>
                            <option value="num_video_items">Num Video Items</option>
                            <option value="square_footage">Square Feet</option>
                        </Form.Select>
                    </Form.Group>

                    {sort_category && (
                        <Form.Group controlId="sort_order" style={{display: 'flex', alignItems: 'center', paddingLeft: '25px'}}>
                            <Form.Select value={sort_order} onChange={(e) => setOrder(e.target.value)}>
                                <option value={`&asc`}>Ascending</option>
                                <option value={`&desc`}>Descending</option>
                            </Form.Select>
                        </Form.Group>
                    )}
                </div>
            </div>
            <div style={{fontSize: '25px', fontWeight: 'bold', color: '#293241'}}>
                Number of libraries: {libraryResult.length}
            </div>
            <Pagination
                numPages={numPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <div style={{fontSize: '20px', fontWeight: 'bold', color: '#293241'}}>
                Displaying {indexOfFirstCard} - {indexOfLastCard} out of {libraryResult.length} Total Libraries:
            </div>
            <Container fluid style={{display:'flex', justifyContent:'center'}}>
                <Row xs={1} sm={2} md={3} lg={4} className="justify-content-md-center" style={{padding: '10px'}}>
                    {currentLibraries.map((library, id) => (
                        <Col key={id} style={{ display: 'flex', justifyContent: 'center', padding: '10px'}}>
                            <LibrariesCard cardQuery={""} id={library.id} imageSrc={"/assets/libraries/library.jpg"} name={library.name} city={library.city} county={library.county} 
                            square_footage={library.square_footage} audio_items={library.num_audio_items} video_items={library.num_video_items} mapSrc = {""}
                            address = {library.address}>
                            </LibrariesCard>
                        </Col>
                    ))}
                </Row> 
            </Container>
            <Pagination
                numPages={numPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </div>
    )
}

export default Libraries;

function getLibrariesFromEndpoint(filters) {
    return fetch(`https://cs373-backend.literatetx.me/get_libraries${filters}`)
        .then(response => response.json())
        .then((jsonLibraries) => {
            return jsonLibraries.result
        })
        .catch((error) => {
            console.error(error);
        })
  }
  