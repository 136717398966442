import React, { useState , useEffect} from 'react';
import CountyCard from './county_card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container';
import Pagination from '../pagination/pagination';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";

function Counties() {
    const [countiesResult, setCountiesResult] = useState([]);
    const getCounties = getCountiesFromEndpoint("");
    const navigate = useNavigate();
    const [sort_category, setCategory] = useState("");
    const [sort_order, setOrder] = useState("");
    const [currentPage, setCurrentPage] = useState(1); // current page the user is on 
    const [cardsPerPage] = useState(15);
    let indexOfLastCard = currentPage * cardsPerPage;
    let indexOfFirstCard = indexOfLastCard - cardsPerPage; 
    
    // total number of pages 
    const numPages = Math.ceil((countiesResult.length - 1) / cardsPerPage);

    // async function getImage(countyName) {
    //   console.log(countyName)
    //   try {
    //     const response = await fetch('https://en.wikipedia.org/w/api.php?action=query&titles=' +String(countyName) +'_County,_Texas&prop=pageimages&format=json&pithumbsize=250&origin=*');
    //     const data = await response.json();
    //     const result = JSON.parse(JSON.stringify(data.query.pages));
    //     const filtered = Object.values(result)
    //     const finalResult = filtered.find(value => value.title.includes(countyName));
    //     console.log(finalResult.thumbnail.source);
    //     return finalResult.thumbnail.source;
    //   } catch (error) {
    //     return [];
    //   }
    // }

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
        setOrder('');
    };

    useEffect(() => {
      console.log(sort_category);
      console.log(sort_order);
      const order = sort_order ? sort_order : '&asc';
      const apiParam = sort_category ? '?sort=' + sort_category + order : '';
      console.log(apiParam);
      getCountiesFromEndpoint(apiParam).then(function(result){
        setCountiesResult(result)
      }) 
    }, [sort_category, sort_order]);
    
    // cards to be displayed on the current page
    let currentCounties = countiesResult.slice(indexOfFirstCard, indexOfLastCard);
    if (currentCounties.length < cardsPerPage) {
        indexOfLastCard = indexOfFirstCard + currentCounties.length;
    }

    const [searchText, setSearchText] = useState('');
    const handleChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            navigateToSearch();
        }
    };

    const navigateToSearch = () => {
        if (searchText.trim() !== '') {
            window.location.href = `/counties_search/${searchText}`;
            setSearchText('');
        }
    };
    
    return (
        <div style={{margin: 'auto', overflow: 'hidden'}}>
            <div style={{backgroundColor: '#293241', color: 'white', fontSize:35, fontWeight:'bold', padding:'25px'}}>
                Counties
            </div>
            <div className="search-and-sort-container" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                <div className='input-box-model'>
                    <FaSearch id="search-counties-icon" onClick={navigateToSearch}/>
                    <input type="search" 
                        name="search-counties" 
                        id="search-counties"
                        data-testid="search-counties"
                        className="search-input" 
                        placeholder="Search counties..." 
                        onKeyUp={handleKeyPress}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3" sm={4} style={{ display: 'flex', justifyContent: 'center', padding: '25px'}}>
                    <Form.Group controlId="sort_category">
                        <Form.Select value={sort_category} onChange={handleCategoryChange}>
                        <option value="">Sort By</option>
                        <option value="population">Population</option>
                        <option value="percent_literacy_lacking">Percent Lacking Literacy</option>
                        <option value="name">Name</option>
                        <option value="literacy_lower_bound">Lower bound</option>
                        <option value="literacy_upper_bound">Upper Bound</option>
                        </Form.Select>
                    </Form.Group>

                    {sort_category && (
                        <Form.Group controlId="sort_order" style={{display: 'flex', alignItems: 'center', paddingLeft: '25px'}}>
                            <Form.Select value={sort_order} onChange={(e) => setOrder(e.target.value)}>
                                <option value={`&asc`}>Ascending</option>
                                <option value={`&desc`}>Descending</option>
                            </Form.Select>
                        </Form.Group>
                    )}
                </div>
            </div>
            <div style={{fontSize: '25px', fontWeight: 'bold', color: '#293241'}}>
                Number of counties: {countiesResult.length}
            </div>
            <Pagination
                numPages={numPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <div style={{fontSize: '20px', fontWeight: 'bold', color: '#293241'}}>
                Displaying {indexOfFirstCard} - {indexOfLastCard} out of {countiesResult.length} Total Counties:
            </div>
            <Container fluid style={{justifyContent:'center'}}>
                <Row xs={1} sm={2} md={3} lg={4} className="justify-content-md-center" style={{padding: '10px'}}>
                    {currentCounties.map((county, id) => (
                        <Col key={id} style={{ display: 'flex', justifyContent: 'center', padding: '10px'}}>
                            <CountyCard cardQuery={""} id={county.id} imageSrc={""} graphSrc={""} name={county.name} population={county.population} num_illiterate={getNumIlliterate(county.percent_literacy_lacking, county.population)} 
                            percent_illiterate={county.percent_literacy_lacking} upper_bound={county.literacy_upper_bound} lower_bound={county.literacy_lower_bound}
                            embed = {""}></CountyCard>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Pagination
                numPages={numPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
      </div>
  
    )
}

export function getNumIlliterate(percent, total){
    return Math.round(total * percent/100)
}

function getCountiesFromEndpoint(filters) {
    return fetch(`https://cs373-backend.literatetx.me/get_counties${filters}`)
        .then(response => response.json())
        .then((jsonCounties) => {
            return jsonCounties.result
        })
        .catch((error) => {
            console.error(error);
        })
}


export default Counties; 